import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useCookies } from 'react-cookie';
import { useNavigate } from 'react-router-dom';
import { 
    useToast, 
    Grid, 
    Box, 
    Image, 
    Divider, 
    Heading, 
    Text, 
    VStack, 
    Button, 
    Select, 
    Menu, 
    MenuButton, 
    MenuList, 
    MenuItem, 
    IconButton, 
    Flex } from '@chakra-ui/react';
import { HamburgerIcon } from '@chakra-ui/icons';

import { backend_uri } from '../config';

interface VPNClient {
    vpn_client_name: string;
    vpn_server: {
        vpn_server_name: string;
        public_ip: string;
    };
}

const Results: React.FC = () => {
    const [cookies, , removeCookie] = useCookies(['access_token', 'username']);
    const navigate = useNavigate();
    const toast = useToast();
    const username = cookies.username || '';
    const [organization, setOrganization] = useState('');
    const [servers, setServers] = useState<Record<string, string[]>>({});
    const [selectedServer, setSelectedServer] = useState<string>('');
    const [selectedClient, setSelectedClient] = useState<string>('');
    const [serverUrls, setServerUrls] = useState<Record<string, string>>({});
    const [generatedUrl, setGeneratedUrl] = useState<string>('');

    const handleLogout = async () => {
        try {
            const deviceUserAgent = navigator.userAgent;
            await axios.post(backend_uri + `/logout`, { username, device_user_agent: deviceUserAgent });
            removeCookie('access_token', { path: '/' });
            removeCookie('username', { path: '/' });
            navigate('/');
        } catch (error) {
            console.error("Failed to log out:", error);
            toast({
                title: 'Failed to log out.',
                description: 'Please try again.',
                status: 'error',
                duration: 5000,
                isClosable: true,
            });
        }
    };

    useEffect(() => {
        const fetchUserOrganisation = async () => {
            try {
                const response = await axios.get(backend_uri + `/user/me`, {
                    headers: {
                        Authorization: `Bearer ${cookies.access_token}`
                    }
                });
                const { belongs_to_org } = response.data;
                setOrganization(belongs_to_org);
            } catch (error) {
                console.error("Failed to fetch user's organization:", error);
                toast({
                    title: 'Failed to fetch user\'s organization.',
                    description: 'Please try again.',
                    status: 'error',
                    duration: 5000,
                    isClosable: true,
                });
            }
        };

        const fetchServersAndClients = async () => {
            try {
                const response = await axios.get<VPNClient[]>(backend_uri + `/vpn_client/all`, {
                    headers: {
                        Authorization: `Bearer ${cookies.access_token}`
                    }
                });
                const vpnClients = response.data;
                const serverClientMap = vpnClients.reduce<Record<string, string[]>>((acc, client) => {
                    const serverName = client.vpn_server.vpn_server_name;
                    if (!acc[serverName]) {
                        acc[serverName] = [];
                    }
                    acc[serverName].push(client.vpn_client_name);
                    return acc;
                }, {});
                const serverUrlMap = vpnClients.reduce<Record<string, string>>((acc, client) => {
                    const serverName = client.vpn_server.vpn_server_name;
                    acc[serverName] = client.vpn_server.public_ip;
                    return acc;
                }, {});
                setServers(serverClientMap);
                setServerUrls(serverUrlMap);
        
                const initialServer = Object.keys(serverClientMap)[0];
                setSelectedServer(initialServer);
                setSelectedClient(serverClientMap[initialServer][0]);
            } catch (error) {
                console.error("Failed to fetch servers and clients:", error);
                toast({
                    title: 'Failed to fetch servers and clients.',
                    description: 'Please try again.',
                    status: 'error',
                    duration: 5000,
                    isClosable: true,
                });
            }
        };
        
        const accessToken = cookies.access_token;
        if (!accessToken) {
            navigate('/');
        } else {
            fetchUserOrganisation();
            fetchServersAndClients();
        }
    }, [cookies.access_token, navigate, toast]);

    const handleServerChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const newServer = event.target.value;
        setSelectedServer(newServer);
        const clients = servers[newServer];
        setSelectedClient(clients[0]); 
        setGeneratedUrl('');
    };
    

    const handleClientChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        setSelectedClient(event.target.value);
        setGeneratedUrl('');
    };

    const handleConnect = () => {
        const selectedServerUrl = serverUrls[selectedServer];
        const redirectionUrl = `http://${selectedServerUrl}:2000/login/${selectedServer}/${selectedClient}/${cookies.access_token}`;
        // const redirectionUrl = `https://${selectedServerUrl}/login/${selectedServer}/${selectedClient}/${cookies.access_token}`;
        window.open(redirectionUrl, '_blank');
    };

    const handleSensor = () => {
        navigate('/create-vpn-server');
    };

    const handleClient = () => {
        navigate('/create-vpn-client');
    };

    const handleNewUser = () => {
        navigate('/create-new-user');
    };

    const UserLog = () => {
        navigate('/user-log');
    };

    const handleOldUser = () => {
        navigate('/remove-user')
    }


    return (
        <Grid templateColumns="375px 1fr" minHeight="100vh">
            <Box bg="gray.100" padding="4" display="flex" flexDirection="column" alignItems="center">
                <Image src="/logo.png" alt="Logo" width="300px" />
                <Divider marginTop="20px" />
                <VStack spacing={6} align="stretch" width="100%" mb={6}>
                    <Text>Welcome <Text as="span" fontWeight="bold">{username}</Text></Text>
                    <Text>Organisation: <Text as="span" fontWeight="bold">{organization}</Text></Text>
                    <Button colorScheme="purple" size="sm" onClick={handleLogout}>Logout</Button>
                </VStack>
            </Box>
            <Box marginLeft="30px" marginRight="auto" width="90%">
                <Flex justifyContent="space-between" alignItems="center" marginTop="10" marginBottom="4">
                    <Heading as="h2" fontSize="4xl" textAlign="left">
                        Welcome to <Text as="span" color="green.400">KALIUM-GLOBAL-SERVER</Text>
                    </Heading>
                    {organization === 'Curium' && (
                        <Menu>
                            <MenuButton as={IconButton} aria-label="Options" icon={<HamburgerIcon />} variant="outline" />
                            <MenuList>
                                <MenuItem onClick={handleSensor}>Create VPN Server</MenuItem>
                                <MenuItem onClick={handleClient}>Create VPN Client</MenuItem>
                                <MenuItem onClick={handleNewUser}>Create New User</MenuItem>
                                <MenuItem onClick={handleOldUser}>Remove User</MenuItem>
                                <MenuItem onClick={UserLog}>User log</MenuItem>
                            </MenuList>
                        </Menu>
                    )}
                </Flex>
                <Divider marginBottom="60px" />
                <Heading as="h3" fontSize="2xl" marginBottom="4">Available Servers and Clients</Heading>
                <Box marginBottom="4">
                    <Text>Select Server:</Text>
                    <Select value={selectedServer} onChange={handleServerChange} aria-label="Select Server">
                        {Object.keys(servers).map(serverName => (
                            <option key={serverName} value={serverName}>{serverName}</option>
                        ))}
                    </Select>
                </Box>
                <Box marginBottom="4">
                    <Text>Select Client:</Text>
                    <Select value={selectedClient} onChange={handleClientChange} aria-label="Select Client">
                        {servers[selectedServer]?.map(clientName => (
                            <option key={clientName} value={clientName}>{clientName}</option>
                        ))}
                    </Select>
                </Box>
                <Button colorScheme="teal" onClick={handleConnect} isDisabled={!selectedServer || !selectedClient}>Connect</Button>
            </Box>
        </Grid>
    );
};

export default Results;
